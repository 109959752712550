import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/hayden/dev/fluidsolarthermal.com/web/src/components/layout-markdown.tsx";
import SEO from '../components/seo';
import HomeSelector from '../components/home-selector';
import Hero from '../components/hero';
import { graphql } from 'gatsby';
export const pageQuery = graphql`
  {
    heroImage: file(relativePath: { eq: "exteriors/D_Lux_3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Your Home" mdxType="SEO" />
    <Hero heroImage={props.data.heroImage} styles={{
      backgroundPositionY: '40%'
    }} mdxType="Hero">
  <h1 className="text-3xl">Your Home</h1>
  <p className="text-xl">Comfortable, affordable, low-impact living—for all Australians.</p>
    </Hero>
    <p>{`Fluid Solar homes are the clear choice for those who’d like to pursue sustainable living and ultimate flexibility. Our modular construction process means that we can deliver and assemble a complete four bedroom home in `}<strong parentName="p">{`less than four days`}</strong>{` on a prepared site, at a fraction of the cost of traditional building methods. Smart and environmentally-conscious design cuts typical energy costs for occupants by as much as 90%, and can be made to function with standard grid connection, reduced grid dependency, or entirely free from the grid.`}</p>
    <p>{`A Modular building approach means that the components for these projects are built in an SA factory, under the supervision of our experienced team of engineers and manufacturers. The modules are transportable by forklift, tractor or a standard-width trailer, and are clamped securely together on a prepared site in a few hours or days. We can deliver and assemble a complete four bedroom home in less than four days on a prepared site, whereas it would take around six to twelve months using conventional on site building techniques. Our methods mean that rural and remote locations, sloping sites and residential areas with small access points are all still feasible build areas.`}</p>
    <p>{`Suitable as holiday homes, rural properties or a permanent suburban residence, our homes emphasise sleek functionality. Explore your options below.`}</p>
    <div className="escape-container">
  <HomeSelector category="categoryHome" mdxType="HomeSelector" />
    </div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      